import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import '../src/style/font-awesome.css'
import '../src/style/global.scss'
import '../src/bootstrap/bootstrap.css'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

  <React.StrictMode>
    <App />
  </React.StrictMode>

);
