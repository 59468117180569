import { Link } from "react-router-dom";
function CreateAccountSuccess() {
  return (<>
    <div className="container mt-5 mb-5">
      <div className="row justify-content-center">
        <div className="col-lg-5">
          <div className="card card-cart-shp mb-3 border-0">
            <div className="card-body text-center pt-4 pb-4">
              <i className="fa fa-check fa-3x mb-3" />
              <h3><b>Registo criado com sucesso</b></h3>
              <p>Enviamos um email para você, por favor confirmar para poder comprar.</p>

              <input type="email" className="form-control border-0 text-center bg-for-sin" value={""} />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="text-center">
      <Link to="/login" className="a">Iniciar sessão</Link>
    </div>
  </>
  );
}
export default CreateAccountSuccess;