import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import { setUserProfileAdmin } from "../util/auth";
import api from "../services/api";

import banner5 from '../assets/img/banner5.png'
import '../style/auth.scss'

function Login_Adm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isNotValidUser, setIsNotValidUser] = useState(false)

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  async function handleLogin(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setLoading(true);

    if (!email) {
      alert("Please enter a valid email");
      setLoading(false);
      return;
    }

    if (!password) {
      alert("Please enter a valid password");
      setLoading(false);
      return;
    }

    const data = {
      email,
      password
    };

    try {
      const userSession = await api.post("/costumer/auth/login", data);

      if (!userSession) {
        setIsNotValidUser(true);
        return;
      }

      await setUserProfileAdmin(userSession.data.token.access_token);

      setLoading(false);
      return navigate("/costumer/dashboard");
    }
    catch (err) {
      setIsNotValidUser(true);
      setLoading(false);
      return;
    }
  }


  return (
    <>
      <div className="main height-width-100">
        <div className="container-fluid p-0">
          <div className="row ">
            <div className="col-lg-5 pt-5 pb-5 height-100 d-flex align-items-center">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-6">
                    <div className='text-center'>
                      <h2>VenusExpress. Costumer</h2>
                      <p>Entrar</p>
                    </div>
                    {
                      isNotValidUser
                        ?
                        <div className="alert alert-danger border-0" role="alert">
                          Senha ou Email errado
                        </div>
                        : ""
                    }
                    <form onSubmit={handleLogin} className='d-grid'>
                      <input type="email" className="form-control border-0 mb-3" value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder='Email' />
                      <input type="password" className="form-control border-0 mb-3" value={password} onChange={(e) => { setPassword(e.target.value) }} placeholder='Senha' />
                      {
                        !loading
                          ?
                          <button className="btn btn-primary border-0">Entrar</button>
                          :
                          <button className="btn btn-primary border-0" disabled>Entrar <ClipLoader size={15} /></button>
                      }
                    </form>
                    <div className="text-pergunta mt-5 mb-4 text-center">
                      <Link className="a" to="/">Voltar para pagina inicial</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 pt-5 pb-5 background-auth height-100 d-flex align-items-center">
              <div className="container">
                <img src={banner5} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Login_Adm;