export function Rodape(){
  return(
    <div className="rodape">
      <div className="container">
        <div className="row">
          <div className="col-6 font-14 pt-1">
            ©2022 Venus, Ltd. Todos os direitos reservados <a href="#" className="link-rodap">· Privacidade</a> <a href="#" className="link-rodap">· Termos</a>
          </div>
          <div className="col-6 text-right">
            <a href="#" className="link-icon-rodap"><i className="fa fa-facebook me-3"></i></a>
            <a href="#" className="link-icon-rodap"><i className="fa fa-instagram me-3"></i></a>
            <a href="#" className="link-icon-rodap"><i className="fa fa-whatsapp"></i></a>
          </div>
        </div>
      </div>
    </div>
  );
}

