import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Table } from "../components/Table";
import api from "../services/api";

import "../style/control_panel.scss"

import { getOnlyTokenAdmin, getUserProfileAdmin, logoutAdmin } from "../util/auth";

interface Purchase {
  id: number;
  reference: string;
  price: string;
  status: string;
  dataTime: string;
  name: string;
  image: string;
}

interface Pagination {
  current_page: number;
  first_page_url: string;
  last_page_url: string;
  per_page: number;
  total: number;
}

interface TokenProps {
  token: string;
  isAdmin: boolean;
}

function Control_Panel() {
  const [name, setName] = useState("");
  const [purchases, setPurchases] = useState<Purchase[]>([]);
  const [purchasesPages, setPurchasesPages] = useState<Pagination[]>([]);

  const [countPending, setCountPending] = useState(0);
  const [countDone, setCountDone] = useState(0);
  const [countOrder, setCountOrder] = useState(0);
  const [countExpired, setCountExpired] = useState(0);

  const navigate = useNavigate();

  function logout() {
    logoutAdmin();

    return navigate('/');
  }

  useEffect(() => {
    const profile = getUserProfileAdmin();
    setName(profile.name);

    getPurchases();
  }, []);

  async function getPurchases() {
    const token = getOnlyTokenAdmin();

    const response = await api.get('/v1/costumer/purchases',
      { headers: { Authorization: 'Bearer ' + token } }
    );

    setPurchases(response.data.results.purchases.data);
    setPurchasesPages(response.data.results.purchases)

    setCountOrder(response.data.results.purchases.total);
    setCountPending(response.data.results.count.pendentes);
    setCountDone(response.data.results.count.entregues);
    setCountExpired(response.data.results.count.expirados)
  }

  return (<>
    <div className="body">
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container">
          <a className="navbar-brand" href="#">VenusExpress Seller Dashboard</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
            <div className="navbar-nav">
              <span className="nav-link active">{name}</span>
              <button className="btn-off-menu" onClick={() => { logout(); }}>Sair</button>
            </div>
          </div>
        </div>
      </nav>
      <div className="container mt-5">
        <div className="row mb-2">
          <div className="col-md-3 mb-4">
            <div className="card card-list">
              <div className="d-flex">
                <div className="card-body">
                  <h2><b>{countOrder}</b></h2>
                  Pedidos
                </div>
                <div className="p-4">
                  <div className="bg-card bg-primary"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4">
            <div className="card card-list">
              <div className="d-flex">
                <div className="card-body">
                  <h2><b>{countDone}</b></h2>
                  Atendidos
                </div>
                <div className="p-4">
                  <div className="bg-card bg-success"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4">
            <div className="card card-list">
              <div className="d-flex">
                <div className="card-body">
                  <h2><b>{countPending}</b></h2>
                  Pendentes
                </div>
                <div className="p-4">
                  <div className="bg-card bg-warning "></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4">
            <div className="card card-list">
              <div className="d-flex">
                <div className="card-body">
                  <h2><b>{countExpired}</b></h2>
                  Expirados
                </div>
                <div className="p-4">
                  <div className="bg-card bg-danger"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Table purchases={purchases} pagination={purchasesPages} />
      </div>
    </div>
  </>);
}
export default Control_Panel;
