import { createContext, ReactNode, useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { CartProps } from '../types';

interface CartProviderProps {
  children: ReactNode;
}

interface UpdateProductAmount {
  productId: string;
  quantidade: number;
}

interface CartContextData {
  currency: number;
  cart: CartProps[];
  completedPurchaseCart: () => void;
  addProduct: (product: CartProps[]) => Promise<void>;
  removeProduct: (productId: string) => void;
  updateProductAmount: ({ productId, quantidade }: UpdateProductAmount) => void;
}

const CartContext = createContext<CartContextData>({} as CartContextData);

export function CartProvider({ children }: CartProviderProps): JSX.Element {
  const [cart, setCart] = useState<CartProps[]>(() => {
    const storagedCart = localStorage.getItem('@VenusExpress:cart');

    if (storagedCart) {
      return JSON.parse(storagedCart);
    }

    return [];
  });

  const [currency, setCurrency] = useState(435.83);

  const addProduct = async (products: CartProps[]) => {
    try {

      const newProducts = products.map(product => ({ ...product, preco: String(parseInt(product.preco) * currency) }));
      //console.log(newProducts);
      setCart(newProducts);
      localStorage.setItem('@VenusExpress:cart', JSON.stringify(newProducts));

    } catch {

      toast.error('Erro na adição do produto');

    }
  };

  const removeProduct = (productId: string) => {
    try {
      const productCart = [...cart];
      const productIndex = productCart.findIndex(product => product.titulo === productId);

      if (productIndex >= 0) {
        productCart.splice(productIndex, 1);
        setCart(productCart);
        localStorage.setItem('@VenusExpress:cart', JSON.stringify(productCart));
      } else {
        throw Error()
      }

    } catch {
      toast.error('Erro na remoção do produto')
    }
  };

  const updateProductAmount = async ({
    productId,
    quantidade,
  }: UpdateProductAmount) => {
    try {
      if (quantidade <= 0) {
        return;
      }

      const updateProductCart = [...cart];
      const productExist = updateProductCart.find(product => product.titulo === productId);

      if (productExist) {
        productExist.quantidade = String(quantidade);
        setCart(updateProductCart);
        localStorage.setItem('@VenusExpress:cart', JSON.stringify(updateProductCart));
      } else {
        throw Error();
      }

    } catch {
      toast.error('Erro na alteração de quantidade do produto')
    }

  };

  const completedPurchaseCart = () => {
    localStorage.setItem('@VenusExpress:cart', JSON.stringify([]));
    setCart([]);
  }

  return (
    <CartContext.Provider
      value={{ currency, cart, addProduct, removeProduct, updateProductAmount, completedPurchaseCart }}
    >
      {children}
    </CartContext.Provider>
  );
}

export function useCart(): CartContextData {
  const context = useContext(CartContext);

  return context;
}
