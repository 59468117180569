import { Components } from "../components/Components";
import { Menu } from "../components/Menu";
function Components_assets(){
  return(<>
  <Menu/>
  <div className="container mt-5 mb-5">
    <div className="row justify-content-center">
      <div className="col-md-5">
        <Components/>
      </div>
    </div>
  </div>
  </>);
}
export default Components_assets;