import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { Rodape } from "../components/Rodape";
import { Menu } from '../components/Menu';
import { useCart } from "../hooks/useCart";
import api from "../services/api";

import { CartProps } from "../types";
import { getToken } from "../util/auth";

import { formatPrice } from "../util/format";

const Cart = (): JSX.Element => {

  const [loading, setLoading] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const [reference, setReference] = useState("");
  const [entrega, setEntrega] = useState(0);

  const { currency, cart, removeProduct, updateProductAmount, completedPurchaseCart } = useCart();

  const cartFormatted = cart.map(product => ({
    ...product,
    priceFormatted: formatPrice(parseFloat(product.preco)),
    subTotal: formatPrice(parseFloat(product.preco) * parseInt(product.quantidade))
  }));

  const total =
    formatPrice(
      cart.reduce((sumTotal, product) => {
        sumTotal += parseInt(product.quantidade) * parseFloat(product.preco);

        return sumTotal + entrega;
      }, 0)
    );

  //setIsCreated(false);


  function handleProductIncrement(product: CartProps) {
    const data = {
      productId: product.titulo,
      quantidade: parseInt(product.quantidade) + 1
    };

    updateProductAmount(data);
  }

  function handleProductDecrement(product: CartProps) {
    const data = {
      productId: product.titulo,
      quantidade: parseInt(product.quantidade) - 1
    };

    updateProductAmount(data);
  }

  function handleRemoveProduct(productId: string) {
    removeProduct(productId);
  }

  function onStartEntrega() {

    if (!entrega)
      return setEntrega(2500);

    setEntrega(0);
  }

  async function handleSubmite(products: CartProps[]) {
    try {
      setLoading(true);

      const token = getToken();

      const data = {
        cart: products
      };

      const response = await api.post("/v1/purchases", data, {
        headers: { 'Authorization': 'Bearer ' + token }
      });

      setReference(response.data.data.purchases.reference);

      completedPurchaseCart();

      setLoading(false);

      setIsCreated(true);

      //return navigate("/check");

    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  }

  return (
    <>
      <Menu />
      <div className="min-height100">
        {
          !isCreated ?
            <div className="container mt-5 mb-5">
              <div className="row justify-content-center">
                <div className="col-lg-5">
                  <h3>Meu carrinho</h3>

                  {cartFormatted.length
                    ?
                    <div className=" mt-4">
                      <div className="card card-cart-shp mb-3 border-0">
                        {
                          cartFormatted.map((product) => (
                            <div className="row g-0" key={product.titulo}>
                              <div className="col-2 p-1 col-md-2">
                                <img src={product.imagem} className="rounded" width={80} />
                              </div>
                              <div className="col-5">
                                <div className="card-body pt-3 pb-2 mar-lif-90">
                                  <h6 className="card-title mt-1"><b> {product.titulo} </b></h6>
                                  <p className="card-text">{product.priceFormatted}</p>
                                </div>
                              </div>
                              <div className='col-1 d-flex align-items-center'>
                                <div className="input-quant justify-content-center">
                                  <button type="button"
                                    disabled={parseInt(product.quantidade) <= 1}
                                    onClick={() => handleProductDecrement(product)}
                                    className="btn">
                                    <b>-</b>
                                  </button>
                                  <input
                                    type="text"
                                    readOnly
                                    value={parseInt(product.quantidade)}
                                    className="form-control"
                                  />
                                  <button
                                    type="button"
                                    className="btn"
                                    onClick={() => handleProductIncrement(product)}
                                  ><b>+</b>
                                  </button>
                                </div>
                              </div>
                              <div className="col-3 d-flex align-items-center">
                                <div className="card-body card-body-subtota pt-2 pb-2 mar-lif-90">
                                  <b>Subtotal</b>
                                  <p className="card-text">{product.subTotal}</p>
                                </div>
                              </div>
                              <div className='col-1  d-flex align-items-center'>
                                <button
                                  className='apagar me-3'
                                  onClick={() => handleRemoveProduct(product.titulo)}
                                >
                                  <i className='fa fa-trash' />
                                </button>
                              </div>
                            </div>
                          ))
                        }

                      </div>

                      <div className="mt-3 d-flex bd-highlight">
                        <div className="me-auto p-2 bd-highlight">
                          <div className="form-check">
                            <label className="form-check-label">
                              Receber em casa
                            </label>
                            <input className="form-check-input" onClick={() => onStartEntrega()} type="checkbox" value="" id="flexCheckDefault" />
                          </div>
                        </div>
                        <div className="p-2 bd-highlight">2500,00 kz</div>
                      </div>
                      <div className="mt-3 d-flex bd-highlight borde-trass pb-2">
                        <div className="me-auto p-2 bd-highlight">Cambio</div>
                        <div className="p-2 bd-highlight">{formatPrice(currency)}</div>
                      </div>
                      <div className="d-flex bd-highlight mt-2">
                        <div className="me-auto p-2 bd-highlight">Total</div>
                        <div className="p-2 bd-highlight"><h5><b>{total}</b></h5></div>
                      </div>
                      <div className="width100 d-flex justify-content-end mt-3">
                        <div>
                          {
                            !loading
                              ?
                              <button type="button" className="btn btn-dark text-left d-flex pt-" onClick={() => handleSubmite(cart)}>
                                <span className='width100 mt-2'>Finalizar pedido</span><span className='mt-2 ms-3'><i className='fa fa-angle-double-right' /></span>
                              </button>
                              :
                              <button type="button" disabled className="btn btn-dark text-left d-flex pt-">
                                <span className='width100 mt-2'>Finalizando pedido...</span><span className='mt-2 ms-3'><i className='fa fa-angle-double-right' /></span>
                                <ClipLoader size={15} />
                              </button>
                          }

                        </div>
                      </div>
                    </div>
                    :
                    <>
                      <div className="alert alert-primary border-0 mt-4" role="alert">
                        Sem produtos no carrinho
                      </div>
                    </>
                  }
                </div>
              </div>
            </div>
            :
            <>
              <div className="container mt-5 mb-5">
                <div className="row justify-content-center">
                  <div className="col-lg-5">
                    <div className="card card-cart-shp mb-3 border-0">
                      <div className="card-body text-center pt-4 pb-4">
                        <i className="fa fa-check fa-3x mb-3" />
                        <h3><b>Compra finalizada</b></h3>
                        <p>Por favor pague a referência abaixo no multicaixa, tem até 24/horas.</p>

                        <input type="email" className="form-control border-0 text-center bg-for-sin" value={reference} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <Link to="/" className="a">Ir para o histórico</Link>
              </div>
            </>
        }
      </div>
      <Rodape />
    </>

  );
}
export default Cart;