export function Modal_inf_rf(){
  return(
    <div className="modal fade" id="inf_referencia" aria-labelledby="inf_referenciaLabel" aria-hidden="true">
    <div className="modal-dialog border-0">
      <div className="modal-content border-0 inf_refer_modal">
        <div className="modal-header border-0">
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
          <div className="d-flex bd-highlight mb-4">
            <div className="bd-highlight me-auto ">
              <h6><b>Referincia</b></h6>
              8888 8888 7777887
            </div>
            <div className="ms-auto bd-highlight">
              <h6 className='text-right'><b>Stado</b></h6>
              <span className="badge rounded-pill bg-warning-badge">Em espera</span>
            </div>
          </div>
          <div className="d-flex bd-highlight mb-4">
            <div className="bd-highlight me-auto ">
              <h6><b>Total da compra</b></h6>
              30.000.00 kz
            </div>
            <div className="text-right ms-auto bd-highlight">
              <h6><b>Quantidade</b></h6>
              <b>4</b>
            </div>
          </div>
          <div className="d-flex bd-highlight">
            <div className="bd-highlight me-auto ">
              <h6><b>Data de Entrega</b></h6>
              99/99/9999
            </div>
            <div className="text-right ms-auto bd-highlight">
              <h6><b>Data de Compra</b></h6>
              09/90/9993
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}