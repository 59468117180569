import '../style/pagiErro.scss';

function Page_error() {
  return (
    <>
      <div className="container height-100 width-100 d-flex align-items-center justify-content-center">
        <div className='text-center pt-5 pb-5'>
          <h1><b>Ups</b></h1>
          <h1 className='h1-400'><b>400</b></h1>
          <h1>Página não encontrada. :/</h1>
          <h4>Desculpe, a página que estas a procura não existe nesse site.</h4>
        </div>
      </div>
    </>
  );
}
export default Page_error;