import api from "../services/api";

interface AdminTokenProps {
  token: string;
  isAdmin: boolean;
}

function getToken() {
  const token = localStorage.getItem('@VenusExpress:session_token');
  return token;
}

function getTokenAdmin(): Object {
  const data = JSON.parse(localStorage.getItem('@VenusSellerExpress:session') || '[]');
  return data;
}

function getOnlyTokenAdmin() {
  const data = JSON.parse(localStorage.getItem('@VenusSellerExpress:session') || '[]');
  return data.token;
}

function logout() {
  localStorage.clear();
  return true;
}

function logoutAdmin() {
  localStorage.clear();
  return true;
}

function userIsLoggedIn() {
  const token = getToken();
  if (token) {
    return true
  } else {
    return false
  }
}

function getUserProfile() {
  const userProfile = JSON.parse(localStorage.getItem('@VenusExpress:user') || "");
  return userProfile;
}

function getUserProfileAdmin() {
  const userProfile = JSON.parse(localStorage.getItem('@VenusSellerExpress:user') || "");
  return userProfile;
}

async function setUserProfile(token: string) {
  const user = await api.get('/auth/me', {
    headers: { 'Authorization': 'Bearer ' + token }
  });

  if (!getToken())
    localStorage.setItem('@VenusExpress:session_token', token);

  localStorage.setItem('@VenusExpress:user', JSON.stringify(user.data));
}

async function setUserProfileAdmin(token: string) {
  const user = await api.get('/costumer/auth/me', {
    headers: { 'Authorization': 'Bearer ' + token }
  });

  const data = {
    token: token,
    isAdmin: true
  }

  if (!Object.keys(getTokenAdmin()).length)
    localStorage.setItem('@VenusSellerExpress:session', JSON.stringify(data))

  localStorage.setItem('@VenusSellerExpress:user', JSON.stringify(user.data));
}

export {
  getToken,
  getTokenAdmin,
  getOnlyTokenAdmin,
  userIsLoggedIn,
  logout,
  logoutAdmin,
  setUserProfile,
  setUserProfileAdmin,
  getUserProfile,
  getUserProfileAdmin
}