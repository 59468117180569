import user from '../assets/photoUser/user.png';
import express from '../assets/img/express.jpeg'
import relogio from '../assets/img/relogio.png'
export function Components (){
  return(
  <>
  <h3 className="mb-3"><b>Formulario</b></h3>
  <input type="text" className="form-control mb-3 border-0"  placeholder="Nome"/>
  <input type="email" className="form-control mb-3 border-0"  placeholder="Email"/>
  <input type="password" className="form-control mb-3 border-0"  placeholder="Senha"/>
  <input type="number" className="form-control mb-3 border-0"  placeholder="Telefone"/>
  <select className="form-select mb-3 border-0">
  <option selected>select</option>
  <option value="1">select1</option>
  </select>
  <div className="d-grid gap-2 mb-3">
  <button type="button" className="btn btn-primary border-0">Primary</button>
  <button type="button" className="btn btn-dark">Dark</button>
  <button type="button" className="btn btn-dark text-left d-flex pt-">
    <span className='width100 mt-2'>Finalisar pagamento</span><span className='mt-2'><i className='fa fa-angle-double-right'/></span>
  </button>
  </div>
  <button type="button" className="btn btn-primary border-0">Primary</button>
  <button type="button" className="btn btn-dark">Dark</button>

  <h3 className="mt-5 mb-3"><b>badges</b></h3>
  <span className="badge m-1 rounded-pill bg-primary-badge">Entregue</span>
  <span className="badge m-1 rounded-pill bg-success-badge">Pago</span>
  <span className="badge m-1 rounded-pill bg-danger-badge">Expirado</span>
  <span className="badge m-1 rounded-pill bg-warning-badge">Em espera</span>
  <span className="badge m-1 rounded-pill bg-info-badge">Referencia Gerada</span>
  <h3 className="mt-5 mb-3"><b>Fotos usuarios</b></h3>
  <div className="d-flex align-items-center">
  <div className="avatar-mini">
  <img src={user} alt="" />
  </div>
  <div className="avatar-medio ms-4">
  <img src={user} alt="" />
  </div>
  <div className="avatar ms-4">
  <img src={user} alt="" />
  </div>
  </div>
  <h3 className="mt-5 mb-3"><b>Dropdown</b></h3>
  <div className="dropdown">
  <button className="btn btn-secondary" type="button" id="dropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
    D
  </button>
  <ul className="dropdown-menu border-0" aria-labelledby="dropdownMenu">
    <li><a className="dropdown-item" href="#">Action</a></li>
    <li><a className="dropdown-item" href="#">Another action</a></li>
    <li><a className="dropdown-item" href="#">Something else here</a></li>
  </ul>
</div>

  <h3 className="mt-5 mb-3"><b>Card Historico de compra</b></h3>
  <div className="card card-historic border-0 mb-3">
  <div className="card-body">
  <div className="d-flex bd-highlight">
  <div className="bd-highlight me-auto ">
  <h6><b>Referincia</b></h6>
    8888 8888 7777887
  </div>
  <div className="ms-auto bd-highlight">
    <h6 className='text-right'><b>Stado</b></h6>
    <span className="badge rounded-pill bg-warning-badge">Em espera</span>
  </div>
  <div className="ps-3 bd-highlight d-flex align-items-center">
    <button className='btn btn-light' data-bs-toggle="modal" data-bs-target="#inf_referencia">
      <i className='fa fa-angle-double-right'/>
    </button>
  </div>
</div>
  </div>
</div>
<div className="card card-historic border-0 mb-3">
  <div className="card-body">
  <div className="d-flex bd-highlight">
  <div className="bd-highlight">
  <h6><b>Referincia</b></h6>
    8888 8888 7777887
  </div>
  <div className="ms-auto bd-highlight">
    <h6 className='text-right'><b>Stado</b></h6>
    <span className="badge m-1 rounded-pill bg-success-badge">Pago</span>
  </div>
  <div className="ps-3 bd-highlight d-flex align-items-center">
    <button className='btn btn-light'>
      <i className='fa fa-angle-double-right'/>
    </button>
  </div>
</div>
  </div>
</div>
<div className="card card-historic border-0 mb-3">
  <div className="card-body">
  <div className="d-flex bd-highlight">
  <div className="bd-highlight">
  <h6><b>Referincia</b></h6>
    8888 8888 7777887
  </div>
  <div className="ms-auto bd-highlight">
    <h6 className='text-right'><b>Stado</b></h6>
    <span className="badge m-1 rounded-pill bg-danger-badge">Expirado</span>
  </div>
  <div className="ps-3 bd-highlight d-flex align-items-center">
    <button className='btn btn-light'>
      <i className='fa fa-angle-double-right'/>
    </button>
  </div>
</div>
  </div>
</div>
<div className="card card-historic border-0 mb-3">
  <div className="card-body">
  <div className="d-flex bd-highlight">
  <div className="bd-highlight">
  <h6><b>Referincia</b></h6>
    8888 8888 7777887
  </div>
  <div className="ms-auto bd-highlight">
    <h6 className='text-right'><b>Stado</b></h6>
    <span className="badge m-1 rounded-pill bg-primary-badge">Entregue</span>
  </div>
  <div className="ps-3 bd-highlight d-flex align-items-center">
    <button className='btn btn-light'>
      <i className='fa fa-angle-double-right'/>
    </button>
  </div>
</div>
  </div>
</div>
<h3 className="mt-5 mb-4"><b>Card de compra</b></h3>
<div className="d-grid">
<div className="card card-cart-shp mb-3 border-0">
  <div className="row g-0">
    <div className="col-2 p-1 col-md-2">
      <img src={relogio} className="rounded" width={80}/>
    </div>
    <div className="col-8">
      <div className="card-body pt-3 pb-2">
        <h6 className="card-title mt-1"><b>Relogio de pulso </b></h6>
        <p className="card-text">30.000.00 kz</p>
      </div>
    </div>
    <div className='col-1 d-flex align-items-center'>
      <div className="input-quant justify-content-center">
      <button type="button" className="btn"><b>-</b></button>
      <input type="email" className="form-control" value={2}/>
      <button type="button" className="btn"><b>+</b></button>
      </div>
    </div>
    <div className='col-1  d-flex align-items-center'>
      <button className='apagar me-3'><i className='fa fa-trash'/></button>
    </div>
  </div>
</div>
<div className="card card-cart-shp mb-3 border-0">
  <div className="row g-0">
    <div className="col-2 p-1 col-md-2">
      <img src={relogio} className="rounded" width={80}/>
    </div>
    <div className="col-8">
      <div className="card-body pt-3 pb-2">
        <h6 className="card-title mt-1"><b>Relogio de pulso </b></h6>
        <p className="card-text">30.000.00 kz</p>
      </div>
    </div>
    <div className='col-1 d-flex align-items-center'>
      <div className="input-quant justify-content-center">
      <button type="button" className="btn"><b>-</b></button>
      <input type="email" className="form-control" value={2}/>
      <button type="button" className="btn"><b>+</b></button>
      </div>
    </div>
    <div className='col-1  d-flex align-items-center'>
      <button className='apagar me-3'><i className='fa fa-trash'/></button>
    </div>
  </div>
</div>
<div className="card card-cart-shp mb-3 border-0">
  <div className="row g-0">
    <div className="col-2 p-1 col-md-2">
      <img src={relogio} className="rounded" width={80}/>
    </div>
    <div className="col-8">
      <div className="card-body pt-3 pb-2">
        <h6 className="card-title mt-1"><b>Relogio de pulso </b></h6>
        <p className="card-text">30.000.00 kz</p>
      </div>
    </div>
    <div className='col-1 d-flex align-items-center'>
      <div className="input-quant justify-content-center">
      <button type="button" className="btn"><b>-</b></button>
      <input type="email" className="form-control" value={2}/>
      <button type="button" className="btn"><b>+</b></button>
      </div>
    </div>
    <div className='col-1  d-flex align-items-center'>
      <button className='apagar me-3'><i className='fa fa-trash'/></button>
    </div>
  </div>
</div>
<div className="mt-1 mb-2 d-flex bd-highlight">
<div className="me-auto p-2 bd-highlight">Total</div>
  <div className="p-2 bd-highlight"><h5><b>540.000.00 Kz</b></h5></div>
</div>
<button type="button" className="btn btn-dark text-left d-flex pt-">
    <span className='width100 mt-2'>Finalisar pagamento</span><span className='mt-2'><i className='fa fa-angle-double-right'/></span>
</button>
</div>

<h3 className="mt-5 mb-3"><b>Card Compra</b></h3>
<div className="card card_inf mb-5 border-0">
  <div className="card-body mt-3">
      <div className="d-flex bd-highlight mb-4">
        <div className="bd-highlight me-auto ">
        <h6><b>Referincia</b></h6>
          8888 8888 7777887
        </div>
          <div className="ms-auto bd-highlight">
            <h6 className='text-right'><b>Stado</b></h6>
            <span className="badge rounded-pill bg-warning-badge">Em espera</span>
          </div>
        </div>
        <div className="d-flex bd-highlight mb-4">
        <div className="bd-highlight me-auto ">
        <h6><b>Total da compra</b></h6>
        30.000.00 kz
        </div>
          <div className="text-right ms-auto bd-highlight">
            <h6><b>Quantidade</b></h6>
            <b>4</b>
          </div>
        </div>
        <div className="d-flex bd-highlight">
        <div className="bd-highlight me-auto ">
        <h6><b>Data de Entrega</b></h6>
          99/99/9999
        </div>
          <div className="text-right ms-auto bd-highlight">
          <h6><b>Data de Compra</b></h6>
          09/90/9993
          </div>
          </div>
          <hr className='mt-4'/>
          <div className="row g-0 mt-3">
            <div className="col-2 p-1 col-md-2">
              <img src={relogio} className="rounded" width={80}/>
            </div>
            <div className="col-7">
              <div className="card-body pt-3 pb-2">
                <h6 className="card-title mt-1"><b>Relogio de pulso </b> <a href="#">Ver link</a></h6>
                <p className="card-text">30.000.00 kz</p>
              </div>
            </div>
            <div className="col-3 pt-3 text-right">
              <b>Quant</b><br />
              1
            </div>
          </div>
        </div>
</div>







<div className="modal fade" id="inf_referencia"  aria-labelledby="inf_referenciaLabel" aria-hidden="true">
  <div className="modal-dialog border-0">
    <div className="modal-content border-0 inf_refer_modal">
      <div className="modal-header border-0">
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <div className="d-flex bd-highlight mb-4">
        <div className="bd-highlight me-auto ">
        <h6><b>Referincia</b></h6>
          8888 8888 7777887
        </div>
          <div className="ms-auto bd-highlight">
            <h6 className='text-right'><b>Stado</b></h6>
            <span className="badge rounded-pill bg-warning-badge">Em espera</span>
          </div>
        </div>
        <div className="d-flex bd-highlight mb-4">
        <div className="bd-highlight me-auto ">
        <h6><b>Total da compra</b></h6>
        30.000.00 kz
        </div>
          <div className="text-right ms-auto bd-highlight">
            <h6><b>Quantidade</b></h6>
            <b>4</b>
          </div>
        </div>
        <div className="d-flex bd-highlight">
        <div className="bd-highlight me-auto ">
        <h6><b>Data de Entrega</b></h6>
          99/99/9999
        </div>
          <div className="text-right ms-auto bd-highlight">
          <h6><b>Data de Compra</b></h6>
          09/90/9993
          </div>
          </div>
        </div>
      </div>
    </div>
</div>

 </>
  );
}