import { useEffect, useState } from "react";

import { Rodape } from "../components/Rodape";
import { Menu } from "../components/Menu";
import user from '../assets/photoUser/user.png';
import { getToken, getUserProfile, setUserProfile } from "../util/auth";
import { ClipLoader } from "react-spinners";
import api from "../services/api";

function Perfil() {
  const [avatar, setAvatar] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  //const [province, setProvince] = useState("");
  const [address, setAddress] = useState("");
  const [fileSelected, setFileSelected] = useState<File>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = getToken();
    setUserProfile(token || "");

    const userProfile = getUserProfile();
    setId(userProfile.id);
    //setAvatar(userProfile.foto);
    setName(userProfile.nome);
    setEmail(userProfile.email);
    setTelefone(userProfile.telefone);
    setAddress(userProfile.address);

    var img = new Image();
    img.src = userProfile.foto;

    img.onload = () => {
      setAvatar(userProfile.foto);
    };
    // Caso não seja possível
    img.onerror = () => {
      setAvatar(user);
    };

  },
    []
  );

  async function handleProfileUpdate(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setLoading(true);

    if (!name) {
      alert("Please enter a valid name");
      setLoading(false);
      return;
    }

    if (!email) {
      alert("Please enter a valid email");
      setLoading(false);
      return;
    }

    if (!telefone) {
      alert("Please enter a valid telefone");
      setLoading(false);
      return;
    }

    if (!address) {
      alert("Please enter a valid address");
      setLoading(false);
      return;
    }

    const data = {
      name,
      email,
      telefone,
      address
    };

    try {
      const token = getToken();

      await api.put(`/v1/users/${id}`, data, {
        headers: { 'Authorization': 'Bearer ' + token }
      });

      await setUserProfile(token || "");

      setLoading(false);
      //return navigate('/');
    }
    catch (err) {
      console.log(err);
    }
  }

  const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;

    if (!fileList) return;

    const url = URL.createObjectURL(fileList[0]);

    setFileSelected(fileList[0]);
    setAvatar(url);
  };

  async function handleImageUpload(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setLoading(true);

    if (!fileSelected) {
      alert("Please enter a valid name");
      setLoading(false);
      return;
    }

    try {
      const token = getToken();

      if (fileSelected) {
        const formData = new FormData();
        formData.append("avatar", fileSelected, fileSelected.name);

        await api.post(`/v1/avatar`, formData, {
          headers: { 'Authorization': 'Bearer ' + token }
        });

        await setUserProfile(token || "");

      }

      setLoading(false);
      return window.location.reload();
    }
    catch (err) {
      alert("Falha ao carregar nova foto.");
      setLoading(false);
    }
  }

  return (<>
    <>
      <Menu />
      <div className="container mt-5 mb-5">
        <div className="row justify-content-center">
          <div className="col-lg-5">
            <div className="card border-0 mb-4">
              <div className="card-body">
                <div className="avatar mb-4">
                  <img src={
                    avatar ? avatar : user
                  } alt="" />
                </div>
                <form onSubmit={handleProfileUpdate}>
                  <label className="mb-2">Nome</label>
                  <input type="text" className="form-control box-s-none border mb-3" value={name} placeholder="Ex: John Doe" onChange={(e) => setName(e.target.value)} />
                  <label className="mb-2">Email</label>
                  <input type="email" className="form-control box-s-none border mb-3" value={email} placeholder="Ex: john.doe@venusexpress.co.ao" onChange={(e) => setEmail(e.target.value)} />
                  <label className="mb-2">Telefone</label>
                  <input type="number" className="form-control box-s-none border mb-3" value={telefone} placeholder="Telefone" onChange={(e) => setTelefone(e.target.value)} />
                  <div className="row">
                    {/*<div className="col-12">
                      <label className="mb-2">Província</label>
                      <select className="form-select form-control box-s-none border mb-3" aria-label="Default select example">
                        <option selected>Selecione sua província</option>
                        <option value="1">Bengo</option>
                        <option value="2">Benguela</option>
                        <option value="3">Bié</option>
                        <option value="4">Cabinda</option>
                        <option value="5">Cuando-Cubango</option>
                        <option value="6">Cunene</option>
                        <option value="7">Huambo</option>
                        <option value="8">Huíla</option>
                        <option value="9">Kwanza Sul</option>
                        <option value="10">Kwanza Norte</option>
                        <option value="11">Luanda</option>
                        <option value="12">Lunda Norte</option>
                        <option value="13">Lunda Sul</option>
                        <option value="14">Malanje</option>
                        <option value="15">Moxico</option>
                        <option value="16">Namibe</option>
                        <option value="17">Uíge</option>
                        <option value="18">Zaire</option>
                      </select>
                </div>*/}
                    <div className="col-12">
                      <label className="mb-2">Endereço</label>
                      <input type="text" className="form-control box-s-none border mb-3" placeholder="Ex: Rua J1, Casa nº 23, Bairro Palanca, Luanda" value={address} onChange={(e) => setAddress(e.target.value)} />
                    </div>
                  </div>
                  {
                    !loading
                      ?
                      <button type="submit" className="btn btn-primary border-0">Salvar</button>
                      :
                      <button type="submit" className="btn btn-primary border-0" disabled>Salvando... <ClipLoader size={15} /></button>
                  }

                </form>
              </div>
            </div>

            {/*<h4 className="mb-3"><b>Altera Senha</b></h4>
            <div className="card border-0 mb-4">
              <div className="card-body">
                <form action="#">
                  <label className="mb-2">Senha nova</label>
                  <input type="password" onChange={(e) => e.target.value} readOnly className="form-control box-s-none border mb-3" placeholder="Senha nova" />
                  <label className="mb-2">Confirma senha nova</label>
                  <input type="password" onChange={(e) => e.target.value} readOnly className="form-control box-s-none border mb-3" placeholder="Confirma senha nova" />
                  <button type="submit" className="btn btn-primary border-0">Alterar senha</button>
                </form>
              </div>
            </div>*/}
            <h4 className="mb-3"><b>Altera foto de perfil</b></h4>
            <div className="card border-0 mb-4">
              <div className="card-body">
                <form onSubmit={handleImageUpload}>
                  <label className="mb-2" htmlFor="photo">
                    <input
                      type="file"
                      onChange={handleImageChange}
                      className="form-control form-control-file box-s-none border mb-3"
                      placeholder="Add imagem"
                      id="photo"
                      name="photo"
                      multiple={false}
                      accept="image/*"
                    />

                    {!loading
                      ?
                      <button type="submit" className="btn btn-primary border-0">Alterar foto</button>
                      :
                      <button type="submit" className="btn btn-primary border-0" disabled>Upload da foto... <ClipLoader size={15} /></button>
                    }
                  </label>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    <Rodape />
  </>);
}
export default Perfil;