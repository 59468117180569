import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { CartProvider } from "./hooks/useCart";

import Site from "./pages/Site";
import Home from "./pages/Home";
import Page_error from "./pages/Page_error";
import Perfil from "./pages/Perfil";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Recover_Pass from "./pages/Recover_Pass";
import Cart from "./pages/Cart";
import Login_Adm from "./pages/Login-Adm";
import Components_assets from "./pages/Components_assets";
import Control_Panel from "./pages/Control_Panel";
import Inf_produt from "./pages/Inf_produt";
import Inf_referencia from "./pages/Inf_referencia";
import Check from "./pages/Check";
import CartImport from "./pages/CartImport";

import ProtectedRoutes from './components/ProtectedRoutes';
import PublicRoutes from "./components/PublicRoutes";
import ProtectedCostumerRoutes from "./components/ProtectedCostumerRoutes";
import EmailSuccess from "./pages/EmailSuccess";
import CreateAccountSuccess from "./pages/CreateAccountSuccess";

function App() {

  return (
    <Router>
      <CartProvider>
        <Routes>
          <Route path="/" element={<Site />} />
          <Route path="/*" element={<Page_error />} />

          <Route path="/email/verify/success" element={<EmailSuccess />} />
          <Route path="/account/success" element={<CreateAccountSuccess />} />


          <Route path="carts">
            <Route path="" element={<CartImport />} />
          </Route>

          <Route path="/" element={<ProtectedRoutes />}>
            <Route path="/history" element={<Home />} />
            <Route path="/perfil" element={<Perfil />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/history/reference/:id" element={<Inf_referencia />} />
            <Route path="/check" element={<Check />} />
            <Route path="/Components_assets" element={<Components_assets />} />
          </Route>

          <Route path="/login" element={<PublicRoutes />} >
            <Route path="/login" element={<Login />} />
          </Route>

          <Route path="/register" element={<PublicRoutes />} >
            <Route path="/register" element={<Register />} />
          </Route>

          <Route path="/forgot" element={<PublicRoutes />} >
            <Route path="/forgot/password" element={<Recover_Pass />} />
          </Route>

          <Route path="/costumer/login" element={<Login_Adm />} />

          <Route path="/costumer" element={<ProtectedCostumerRoutes />} >
            <Route path="/costumer/products/information/:id" element={<Inf_produt />} />
            <Route path="/costumer/dashboard" element={<Control_Panel />} />
          </Route>
        </Routes>
      </CartProvider>
    </Router>
  );
}

export default App;
