import { Navigate, Outlet } from 'react-router-dom'

const useAuth = () => {
  const user = JSON.parse(localStorage.getItem('@VenusSellerExpress:session') || '[]');
  if (user.isAdmin) {
    return true
  } else {
    return false
  }
}

const ProtectedCostumerRoutes = (props: any) => {

  const auth = useAuth()

  return auth ? <Outlet /> : <Navigate to="/costumer/login" />
}

export default ProtectedCostumerRoutes;