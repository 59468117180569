import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import { Menu } from "../components/Menu";
import { useCart } from "../hooks/useCart";

import { CartProps } from "../types";
import { userIsLoggedIn } from "../util/auth";

import { urlEncodeToJson } from "../util/urlDecode";

function CartImport() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();


  const { addProduct } = useCart();

  useEffect(() => {
    setLoading(true);

    const products = urlEncodeToJson(searchParams.get("cartProducts") || '');
    handleAddProduct(products.cart);
    //console.log(products.cart);

    setLoading(false);

  }, []);

  async function handleAddProduct(products: CartProps[]) {

    await addProduct(products);
  }

  return (<>
    {
      userIsLoggedIn()
        ?
        <>
          <Menu />
          {
            loading
              ?
              <div className="container mt-5 mb-5">
                <div className="row justify-content-center">
                  <div className="col-lg-5">
                    <div className="card card-cart-shp mb-3 border-0">
                      <div className="card-body text-center pt-4 pb-4">
                        <h3><b>Importando produtos...</b></h3>
                        <p><ClipLoader size={15} /></p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :
              <>
                <div className="container mt-5 mb-5">
                  <div className="row justify-content-center">
                    <div className="col-lg-5">
                      <div className="card card-cart-shp mb-3 border-0">
                        <div className="card-body text-center pt-4 pb-4">
                          <i className="fa fa-check fa-3x mb-3" />
                          <h3><b>Importação de produtos concluído</b></h3>
                          <p></p>

                          <input type="text" readOnly className="form-control border-0 text-center bg-for-sin" value={""} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <Link to="/cart" className="a">Ir para o carrinho</Link>
                </div>
              </>

          }

        </>
        :
        <>
          <nav className="navbar navbar-dark bg-dark pt-3 pb-3">
            <div className="container">
              <Link to="/site" className="navbar-brand">VenusExpress.</Link>
              <div className="d-flex">
                <button className="btn border-0 btn-primary pt-2 pb-2 rounded-pill ps-4 pe-4 ms-2" onClick={() => { navigate("/login"); }}>Entrar</button>
              </div>
            </div>
          </nav>

          {
            loading
              ?
              <div className="container mt-5 mb-5">
                <div className="row justify-content-center">
                  <div className="col-lg-5">
                    <div className="card card-cart-shp mb-3 border-0">
                      <div className="card-body text-center pt-4 pb-4">
                        <h3><b>Importando produtos...</b></h3>
                        <p><ClipLoader size={15} /></p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :
              <>
                <div className="container mt-5 mb-5">
                  <div className="row justify-content-center">
                    <div className="col-lg-5">
                      <div className="card card-cart-shp mb-3 border-0">
                        <div className="card-body text-center pt-4 pb-4">
                          <i className="fa fa-check fa-3x mb-3" />
                          <h3><b>Importação de produtos concluído</b></h3>
                          <p>Por favor, faça login para adquirir os produtos</p>

                          <input type="text" readOnly className="form-control border-0 text-center bg-for-sin" value={""} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <Link to="/cart" className="a">Fazer login</Link>
                </div>
              </>

          }

        </>
    }

  </>);
}
export default CartImport;