import React, { useState } from "react";
import '../style/auth.scss'
import logoGoogle from '../assets/google.svg'
import banner4 from '../assets/img/banner4.png'
import { Link, useNavigate } from 'react-router-dom';
import api from "./../services/api";
import { ClipLoader } from "react-spinners";

function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [password, setPassword] = useState("");

  const [isNotValidUser, setIsNotValidUser] = useState(false)
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  async function handleRegister(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    setLoading(true);

    if (!email) {
      alert("Please enter a valid email");
      setLoading(false);
      return;
    }

    if (!password) {
      alert("Please enter a valid password");
      setLoading(false);
      return;
    }

    if (!email) {
      alert("Please enter a valid email");
      setLoading(false);
      return;
    }

    if (!telefone) {
      alert("Please enter a valid telefone");
      setLoading(false);
      return;
    }

    const data = {
      name,
      email,
      telefone,
      password
    };
    try {

      await api.post("/register", data);
      //alert('Usuário registado com success, faça login na sua conta');

      setLoading(false);
      navigate('/account/success');
    }
    catch (err) {
      console.log(err);
      setIsNotValidUser(true);
      setLoading(false);
      return;
    }
  }
  return (<>
    <div className="main height-width-100">
      <div className="container-fluid p-0">
        <div className="row ">
          <div className="col-lg-5 pt-5 pb-5 height-100 d-flex align-items-center">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6">
                  <div className='text-center'>
                    <Link className="link-text" to="/"><h2>VenusExpress.</h2></Link>
                    <p>Entrar</p>
                  </div>
                  <form onSubmit={handleRegister} className='d-grid'>
                    {
                      isNotValidUser
                        ?
                        <div className="alert alert-danger border-0" role="alert">
                          Ocorreu um erro ao registar usuário, por favor, tente mais tarde!
                        </div>
                        : ""
                    }
                    <input type="text" className="form-control mb-3 border-0" value={name} required onChange={(e) => { setName(e.target.value) }} placeholder="Nome" />
                    <input type="email" className="form-control mb-3 border-0" value={email} required onChange={(e) => { setEmail(e.target.value) }} placeholder="Emial" />
                    <input type="number" className="form-control mb-3 border-0" value={telefone} required onChange={(e) => { setTelefone(e.target.value) }} placeholder="Telefone" />
                    <input type="password" className="form-control mb-3 border-0" value={password} required onChange={(e) => { setPassword(e.target.value) }} placeholder="Senha" />
                    {
                      !loading
                        ?
                        <button type="submit" className="btn btn-primary border-0">Cadastrar</button>
                        :
                        <button className="btn btn-primary border-0" disabled>Cadastrando... <ClipLoader size={15} /></button>
                    }
                  </form>
                  {/*<div className='separator mt-4'>Ou entrar com</div>
                  <div className='text-center mt-3 mb-3 linkes'>
                    <button type="button" className="btn btn-light m-2"><img src={logoGoogle} alt="" /></button>
                    <button type="button" className="btn btn-primary m-2"><i className='fa fa-facebook'></i></button>
                    <button type="button" className="btn btn-dark m-2"><i className='fa fa-apple'></i></button>
                  </div>*/}
                  <div className="text-pergunta mt-5 mb-4 text-center">
                    Já tenho uma conta <Link className="a" to="/login">Entrar</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 pt-5 pb-5 background-auth height-100 d-flex align-items-center">
            <div className="container">
              <img src={banner4} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
}
export default Register;