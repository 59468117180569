import { Menu } from '../components/Menu';
import { Modal_inf_rf } from '../components/Modal_inf_rf';
import { Link } from "react-router-dom";
import { Rodape } from '../components/Rodape';
import { useEffect, useState } from 'react';

import api from '../services/api';

interface PurchasesProp {
  id: number;
  reference: string;
  price: string;
  status: string;
  dateTime: Date;
}

function Home() {

  const [loading, setLoading] = useState(false);
  const [purchases, setPurchases] = useState<PurchasesProp[]>([]);

  useEffect(() => {

    loadPurchaseHistory();

  }, []);

  async function loadPurchaseHistory() {
    try {
      setLoading(true);

      const response = await api.get('v1/purchases', {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('@VenusExpress:session_token'),
        }
      });

      setPurchases(response.data.results.purchases);
      setLoading(false);
    } catch (err) {
      alert('Error: ' + err);
    }

  }
  return (<>
    <Menu />
    <div className="min-height100">
      <div className="container mt-5 mb-5">
        <div className="row justify-content-center">
          <div className="col-lg-5">
            <h3>Histórico de compra</h3>

            {
              loading ?
                <>
                  <div className="card card-historic border-0 mb-3 mt-4">
                    <div className="card-body placeholder-glow">
                      <div className="d-flex bd-highlight">
                        <div className="bd-highlight me-auto ">
                          <h6><b>Referência</b></h6>
                          <span className="placeholder w-75"></span>
                        </div>
                        <div className="ms-auto bd-highlight">
                          <h6 className='text-right'><b>Estado</b></h6>
                          <span className="placeholder w-75"></span>
                        </div>
                        <div className="ps-3 bd-highlight d-flex align-items-center">
                          <span className="placeholder w-75"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card card-historic border-0 mb-3">
                    <div className="card-body placeholder-glow">
                      <div className="d-flex bd-highlight">
                        <div className="bd-highlight me-auto ">
                          <h6><b>Referência</b></h6>
                          <span className="placeholder w-75"></span>
                        </div>
                        <div className="ms-auto bd-highlight">
                          <h6 className='text-right'><b>Estado</b></h6>
                          <span className="placeholder w-75"></span>
                        </div>
                        <div className="ps-3 bd-highlight d-flex align-items-center">
                          <span className="placeholder w-75"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card card-historic border-0 mb-3">
                    <div className="card-body placeholder-glow">
                      <div className="d-flex bd-highlight">
                        <div className="bd-highlight me-auto ">
                          <h6><b>Referência</b></h6>
                          <span className="placeholder w-75"></span>
                        </div>
                        <div className="ms-auto bd-highlight">
                          <h6 className='text-right'><b>Estado</b></h6>
                          <span className="placeholder w-75"></span>
                        </div>
                        <div className="ps-3 bd-highlight d-flex align-items-center">
                          <span className="placeholder w-75"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card card-historic border-0 mb-3">
                    <div className="card-body placeholder-glow">
                      <div className="d-flex bd-highlight">
                        <div className="bd-highlight me-auto ">
                          <h6><b>Referência</b></h6>
                          <span className="placeholder w-75"></span>
                        </div>
                        <div className="ms-auto bd-highlight">
                          <h6 className='text-right'><b>Estado</b></h6>
                          <span className="placeholder w-75"></span>
                        </div>
                        <div className="ps-3 bd-highlight d-flex align-items-center">
                          <span className="placeholder w-75"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                :
                <>
                  {purchases.length ?
                    <div className='mt-4'>
                      {purchases.map(purchase => (
                        <div className="card card-historic border-0 mb-3" key={purchase.id}>
                          <div className="card-body">
                            <div className="d-flex bd-highlight">
                              <div className="bd-highlight me-auto ">
                                <h6><b>Referência</b></h6>
                                {purchase.reference}
                              </div>
                              <div className="ms-auto bd-highlight">
                                <h6 className='text-right'><b>Estado</b></h6>
                                {

                                  purchase.status === 'PAGO' ?
                                    <span className="badge m-1 rounded-pill bg-success-badge">Pago</span>
                                    :

                                    purchase.status === 'COMPRANDO' ?
                                      <span className="badge m-1 rounded-pill bg-success-badge">Pago</span>
                                      :

                                      purchase.status === 'ENTREGUE' ?
                                        <span className="badge rounded-pill bg-success-tb">Entregue</span>
                                        :

                                        purchase.status === 'EXPIRADO' ?
                                          <span className="badge rounded-pill bg-danger-tb">Expirado</span>
                                          :
                                          <span className="badge rounded-pill bg-warning-badge">Pendente</span>


                                }

                              </div>
                              <div className="ps-3 bd-highlight d-flex align-items-center">
                                <Link className='btn btn-light' to={`/history/reference/${purchase.id}`}>
                                  <i className='fa fa-angle-double-right' />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                      )}

                    </div>

                    :
                    <div className="alert alert-primary border-0 mt-4" role="alert">
                      Sem histórico de compra realizada
                    </div>
                  }
                </>
            }
          </div>
        </div>
      </div>
    </div>
    <Modal_inf_rf />
    <Rodape />
  </>);

}
export default Home;