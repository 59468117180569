import { Menu } from "../components/Menu";
import { Link } from "react-router-dom";
function Check() {
  return (<>
    <Menu />
    <div className="container mt-5 mb-5">
      <div className="row justify-content-center">
        <div className="col-lg-5">
          <div className="card card-cart-shp mb-3 border-0">
            <div className="card-body text-center pt-4 pb-4">
              <i className="fa fa-check fa-3x mb-3" />
              <h3><b>Compra finalizada</b></h3>
              <p>Por favor pague a referência abaixo no multicaixa, tem até 24/horas.</p>

              <input type="email" className="form-control border-0 text-center bg-for-sin" value={"663883 8399993 003039"} />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="text-center">
      <Link to="/history" className="a">Ir para o histórico</Link>
    </div>
  </>
  );
}
export default Check;