import { Link } from "react-router-dom";
import '../style/table.scss';
import user from '../assets/photoUser/user.png'
import { useState } from "react";

interface Purchase {
  id: number;
  reference: string;
  price: string;
  status: string;
  dataTime: string;
  name: string;
  image: string;
}

interface Pagination {
  current_page: number;
  first_page_url: string;
  last_page_url: string;
  per_page: number;
  total: number;
}

export function Table(props: { purchases: Purchase[], pagination: Pagination[] }) {
  const { purchases, pagination } = props;
  const [filter, setFilter] = useState("PENDENTE");

  function handleFilterChange(e: string) {
    setFilter(e)
  }

  return (<>
    <div className="row mb-4 justify-content-end">
      <div className="col-3">
        <div className="input-group">
          <samp className='input-group-text'>
            <i className='fa fa-search'></i>
          </samp>
          <input type="number" defaultValue="" className='form-control' placeholder='Pesquisar referencia' />
        </div>
      </div>
      <div className="col-2">
        <select className="form-select"
          onChange={
            (e) => handleFilterChange(e.target.value)
          }
          value="PENDENTE"
          aria-label="Filter">
          <option value="PENDENTE">Pendente</option>
          <option value="PAGO">Pago</option>
          <option value="COMPRANDO">Comprando</option>
          <option value="ENTREGUE">Entregue</option>
          <option value="EXPIRADO">Expirado</option>
        </select>
      </div>
    </div>
    <div className="card card-table mt-3">
      <div className="card-body pt-2 pb-0">
        <table className="table">
          <thead>
            <tr>
              <th scope="col" className='pb-3'>N</th>
              <th scope="col" className='pb-3'>Referencia</th>
              <th scope="col" className='pb-3'>Usuário</th>
              <th scope="col" className='pb-3'>ID</th>
              <th scope="col" className='pb-3'>Valor</th>
              <th scope="col" className='pb-3'>Status</th>
              <th scope="col" className='pb-3'>Data de pedido</th>
              <th scope="col" className='text-right pb-3'>Acções</th>
            </tr>
          </thead>
          <tbody>
            {
              purchases ?

                <>
                  {purchases
                    .filter(dataFilter => dataFilter.status === filter)
                    .map(purchase => (
                      <tr key={purchase.id}>
                        <td>{purchase.id}</td>
                        <th className='pt-3' scope="row">{purchase.reference}</th>
                        <td>
                          {
                            purchase.image
                              ?
                              <img src={purchase.image} alt="" width={30} className="rounded-circle me-2" />
                              :
                              <img src={purchase.image} alt="" width={30} className="rounded-circle me-2" />
                          }

                          {purchase.name}

                        </td>
                        <td>{purchase.id}</td>
                        <td>
                          {
                            new Intl.NumberFormat('pt-AO', {
                              style: 'currency',
                              currency: 'AOA'
                            }).format(parseInt(purchase.price))
                          }
                        </td>
                        <td>
                          {

                            purchase.status === 'PAGO' ?
                              <span className="badge m-1 rounded-pill bg-success-badge">Pago</span>
                              :

                              purchase.status === 'COMPRANDO' ?
                                <span className="badge m-1 rounded-pill bg-success-badge">Em andamento</span>
                                :

                                purchase.status === 'ENTREGUE' ?
                                  <span className="badge rounded-pill bg-success-tb">Entregue</span>
                                  :

                                  purchase.status === 'EXPIRADO' ?
                                    <span className="badge rounded-pill bg-danger-tb">Expirado</span>
                                    :
                                    <span className="badge rounded-pill bg-warning-badge">Pendente</span>


                          }
                        </td>
                        <td>{purchase.dataTime}</td>
                        <td className='text-right'>
                          <div className="dropdown">
                            <button className="link-tab btn-drop" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                              <i className="fa fa-ellipsis-v"></i>
                            </button>
                            <ul className="dropdown-menu shadow  border-0" aria-labelledby="dropdownMenuButton1">
                              <li><Link to={`/costumer/products/information/${purchase.id}`} className="dropdown-item" >Informação</Link></li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    )
                    )}

                </>

                :
                <tr>
                  <td colSpan={8}>Sem lista de compras</td>
                </tr>
            }



          </tbody>
        </table>
      </div>
    </div>
    {/*<div className="mt-3 d-flex">
      <div className='width100'>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li className="page-item">
              <a className="page-link active" href="#" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li className="page-item"><a className="page-link" href="#">1</a></li>
            <li className="page-item"><a className="page-link" href="#">2</a></li>
            <li className="page-item"><a className="page-link" href="#">3</a></li>
            <li className="page-item">
              <a className="page-link active" href="#" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div className='width100 text-right pt-2'>
        <b>30 Paginas</b>
      </div>
          </div>*/}
  </>);
}