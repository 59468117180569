import { Link } from 'react-router-dom';

import '../style/auth.scss'

function Recover_Pass() {
  return (
    <>
      <div className="main height-width-100 pt-5 pb-5 d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4">
              <div className='text-center'>
                <h2>VenusExpress.</h2>
                <p>Recuperar senha</p>
              </div>
              <form action="" className='d-grid'>
                <div className="alert alert-success border-0" role="alert">
                  O email para a recuperação da sua conta foi enviada com sucesso !
                </div>
                <div className="alert alert-danger border-0" role="alert">
                  Esse email nao existe !
                </div>
                <input type="email" className="form-control border-0 mb-3" placeholder='Email' />
                <button className="btn btn-primary border-0" type="button">Entrar</button>
              </form>
              <div className="text-pergunta mt-5 mb-4 text-center">
                <Link to="/login" className='a'>Volta para pagina inicial</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Recover_Pass;