import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Menu } from "../components/Menu";
import { Rodape } from "../components/Rodape";
import api from "../services/api";

import '../style/infreferencia.scss';
import { getToken } from "../util/auth";
import { formatPrice } from "../util/format";

import box from '../assets/img/boxi.png'
interface Purchase {
  id: number;
  reference: string;
  price: string;
  status: string;
  user_id: number;
  created_at: string;
}

interface Products {
  id: number;
  link: string;
  image: string;
  size: string;
  color: string;
  preco: number;
  title: string;
  purchase_id: number;
  quantity: number;
}

function Inf_referencia() {
  const { id } = useParams();
  const [purchaseDetails, setPurchaseDetails] = useState<Purchase>({} as Purchase);
  const [products, setProducts] = useState<Products[]>([]);

  //const [loading, setLoading] = useState(false);

  useEffect(() => {


    loadProductsDetails();

  }, []);

  async function loadProductsDetails() {
    const token = getToken();

    const response = await api.get(`/v1/purchases/products/${id}`,
      { headers: { Authorization: 'Bearer ' + token } }
    );

    setPurchaseDetails(response.data.results.purchase);
    setProducts(response.data.results.products);
  }

  return (<>
    <Menu />
    <div className="container mt-5 mb-5 min-height-100">
      <div className="row justify-content-center">
        <div className="col-lg-5">
          <div className="card border-0">
            <div className="card-body">
              <div className="d-flex bd-highlight mb-4">
                <div className="bd-highlight me-auto ">
                  <h6><b>Referência</b></h6>
                  {purchaseDetails.reference}
                </div>
                <div className="ms-auto bd-highlight">
                  <h6 className='text-right'><b>Estado</b></h6>
                  {

                    purchaseDetails.status === 'PAGO' ?
                      <span className="badge m-1 rounded-pill bg-success-badge">Pago</span>
                      :

                      purchaseDetails.status === 'COMPRANDO' ?
                        <span className="badge m-1 rounded-pill bg-success-badge">Em andamento</span>
                        :

                        purchaseDetails.status === 'ENTREGUE' ?
                          <span className="badge rounded-pill bg-success-tb">Entregue</span>
                          :

                          purchaseDetails.status === 'EXPIRADO' ?
                            <span className="badge rounded-pill bg-danger-tb">Expirado</span>
                            :
                            <span className="badge rounded-pill bg-warning-badge">Pendente</span>

                  }
                </div>
              </div>
              <div className="d-flex bd-highlight mb-4">
                <div className="bd-highlight me-auto ">
                  <h6><b>Total da compra</b></h6>
                  {formatPrice(parseInt(purchaseDetails.price))}
                </div>
                <div className="text-right ms-auto bd-highlight">
                  <h6><b>----</b></h6>
                  <b>--</b>
                </div>
              </div>
              <div className="d-flex bd-highlight">
                <div className="bd-highlight me-auto ">
                  <h6><b>Data de Entrega</b></h6>
                  ------
                </div>
                <div className="text-right ms-auto bd-highlight">
                  <h6><b>Data de Compra</b></h6>
                  {purchaseDetails.created_at}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 mb-5">
            <div className="d-flex justify-content-between">
              {
                purchaseDetails.status === 'PEDENTE'
                  ?
                  <>
                    <div className="progre-entre d-flex align-items-center justify-content-center active"><span><img src={box} alt="" width={30}/></span></div>
                    <div className="progre-entre d-flex align-items-center justify-content-center"><span></span></div>
                    <div className="progre-entre d-flex align-items-center justify-content-center"><span></span></div>
                    <div className="progre-entre d-flex align-items-center justify-content-center"><span></span></div>
                  </>
                  :
                  purchaseDetails.status === 'PAGO'
                    ?
                    <>
                      <div className="progre-entre d-flex align-items-center justify-content-center"><span></span></div>
                      <div className="progre-entre d-flex align-items-center justify-content-center active"><span><img src={box} alt="" width={30}/></span></div>
                      <div className="progre-entre d-flex align-items-center justify-content-center"><span></span></div>
                      <div className="progre-entre d-flex align-items-center justify-content-center"><span></span></div>
                    </>
                    :
                    purchaseDetails.status === 'COMPRANDO'
                      ?
                      <>
                        <div className="progre-entre d-flex align-items-center justify-content-center"><span></span></div>
                        <div className="progre-entre d-flex align-items-center justify-content-center"><span></span></div>
                        <div className="progre-entre d-flex align-items-center justify-content-center active"><span><img src={box} alt="" width={30}/></span></div>
                        <div className="progre-entre d-flex align-items-center justify-content-center"><span></span></div>
                      </>
                      :

                      <>
                        <div className="progre-entre d-flex align-items-center justify-content-center"><span></span></div>
                        <div className="progre-entre d-flex align-items-center justify-content-center"><span></span></div>
                        <div className="progre-entre d-flex align-items-center justify-content-center"><span></span></div>
                        <div className="progre-entre d-flex align-items-center justify-content-center active"><span><img src={box} alt="" width={30}/></span></div>
                      </>

              }
            </div>

            <div className="container">
              <div className="progress">
                <div className="progress-bar" role="progressbar" />
              </div>
            </div>
          </div>

          <div className="mt-5 pt-4">
            <div className="card card-cart-shp mb-3 border-0">
              {
                products.map((product) => (
                  <div className="row g-0" key={product.id}>
                    <div className="col-2 p-1 col-md-2">
                      <img src={product.image} className="rounded" width={80} />
                    </div>
                    <div className="col-4">
                      <div className="card-body pt-3 pb-2 mar-lif-90">
                        <h6 className="card-title mt-1"><b> Nome: </b></h6>
                        <p className="card-text">{product.title.substring(0, 100)}</p>
                      </div>
                    </div>
                    <div className="col-3 d-flex align-items-center">
                      <div className="card-body card-body-subtota pt-2 pb-2 mar-lif-90">
                        <b>Quantidade</b>
                        <p className="card-text">{product.quantity}</p>
                      </div>
                    </div>
                    <div className="col-2 d-flex align-items-center">
                      <div className="card-body card-body-subtota pt-2 pb-2 mar-lif-90">
                        <b>Preço/Unidade</b>
                        <p className="card-text">{formatPrice(product.preco)}</p>
                      </div>
                    </div>
                  </div>
                ))
              }
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <Rodape />
  </>);
}
export default Inf_referencia;