import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import user from '../assets/photoUser/user.png';

import api from "../services/api";
import { getOnlyTokenAdmin, getUserProfileAdmin, logoutAdmin } from "../util/auth";
import { formatPrice } from "../util/format";
import { ClipLoader } from "react-spinners";

interface Seller {
  foto: string;
  name: string;
  email: string;
  telefone: string;
  address: string;

  reference: string;
  price: number;
  status: string;
  purchaseDate: string;
  deliveryDate: string;
}

interface Products {
  id: number;
  link: string;
  image: string;
  size: string;
  color: string;
  price: string;
  title: string;
  quantity: number;
  purchase_id: number
}

interface Purchase {
  total: number;
  status: string;
  purchaseDate: Date;
  deliveryDate: Date;
}

function Inf_produt() {
  const [name, setName] = useState("");
  const params = useParams();

  const [purchaseDetails, setPurchaseDetails] = useState<Products[]>([]);
  const [sellerDetails, setSellerDetails] = useState<Seller>({} as Seller);
  const [changeState, setChangeState] = useState("");

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  function logout() {
    logoutAdmin();

    return navigate('/');
  }

  useEffect(() => {
    const profile = getUserProfileAdmin();
    setName(profile.name);

    loadProductsDetails();

  }, []);

  async function loadProductsDetails() {
    const token = getOnlyTokenAdmin();

    const response = await api.get(`/v1/costumer/purchases/products/${params.id}`,
      { headers: { Authorization: 'Bearer ' + token } }
    );

    setPurchaseDetails(response.data.results.products);
    setSellerDetails(response.data.results.seller);
  }

  async function handleChangeState(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const token = getOnlyTokenAdmin();

    try {

      setLoading(true);

      if (!changeState) {
        alert("Por favor selecione o status");
        setLoading(false);
      }

      const data = {
        status: changeState
      };

      const response = await api.put(`/v1/costumer/purchases/products/${params.id}`,
        data,
        { headers: { Authorization: 'Bearer ' + token } }
      );

      if (response.data.success) {
        alert("Status alterado com sucesso");
      }

      loadProductsDetails();
      setLoading(false);

    } catch (err) {
      alert('Falha ao mudar status tente outra vez')
      setLoading(false);

    }

  }

  return (<>
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container">
        <a className="navbar-brand" href="#">VenusExpress Seller Dashboard</a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <span className="nav-link active">{name}</span>
            <button className="btn-off-menu" onClick={() => { logout(); }}>Sair</button>
          </div>
        </div>
      </div>
    </nav>
    <div className="container mt-5 mb-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card card_inf mb-5 border-0">
            <div className="card-body mt-3">
              <div className="d-flex bd-highlight mb-4">
                <div className="bd-highlight me-auto ">
                  <h6><b>Referência</b></h6>
                  {sellerDetails.reference}
                </div>
                <div className="ms-auto bd-highlight">
                  <h6 className='text-right'><b>Estado</b></h6>
                  {

                    sellerDetails.status === 'PAGO' ?
                      <span className="badge m-1 rounded-pill bg-success-badge">Pago</span>
                      :

                      sellerDetails.status === 'COMPRANDO' ?
                        <span className="badge m-1 rounded-pill bg-success-badge">Em andamento</span>
                        :

                        sellerDetails.status === 'ENTREGUE' ?
                          <span className="badge rounded-pill bg-success-tb">Entregue</span>
                          :

                          sellerDetails.status === 'EXPIRADO' ?
                            <span className="badge rounded-pill bg-danger-tb">Expirado</span>
                            :
                            <span className="badge rounded-pill bg-warning-badge">Pendente</span>

                  }
                </div>
              </div>
              <div className="d-flex bd-highlight mb-4">
                <div className="bd-highlight me-auto ">
                  <h6><b>Total da compra</b></h6>
                  {formatPrice(sellerDetails.price)}
                </div>
                <div className="text-right ms-auto bd-highlight">
                  <h6><b>-------</b></h6>
                  <b>---</b>
                </div>
              </div>
              <div className="d-flex bd-highlight">
                <div className="bd-highlight me-auto ">
                  <h6><b>Data de Entrega</b></h6>
                  {
                    sellerDetails.deliveryDate
                      ?
                      sellerDetails.deliveryDate
                      :
                      <p>------</p>
                  }
                </div>
                <div className="text-right ms-auto bd-highlight">
                  <h6><b>Data de Compra</b></h6>
                  {
                    sellerDetails.purchaseDate
                      ?
                      sellerDetails.purchaseDate
                      :
                      <p>Referência pendente</p>
                  }
                </div>
                <div className="text-right ms-auto bd-highlight">
                  <h6><b>Entregue</b></h6>
                  Correios
                </div>
              </div>
              <div className="d-flex mt-6 bd-highlight">
                {/*<div className="form-check bd-highlight mt-6">
                  <input className="form-check-input" type="radio" />
                  <label className="form-check-label">
                    Pagamento concluído
                </label>*
                </div>*/}
                <div className="form-check ms-auto">
                  <form onSubmit={handleChangeState} className="d-flex mt-6 bd-highlight">
                    <select
                      className="form-select border"
                      aria-label="Default select example"
                      value={changeState}
                      onChange={
                        (e) => setChangeState(e.target.value)
                      }
                    >
                      <option selected>{sellerDetails.status}</option>
                      <option value="PAGO">Pago</option>
                      <option value="COMPRANDO">Comprando</option>
                      <option value="ENTREGUE">Entregue</option>
                    </select>
                    {
                      !loading
                        ?
                        <button type="submit" className="btn btn-primary">Alterar</button>
                        :
                        <button type="submit" className="btn btn-primary" disabled>Alterando... <ClipLoader size={10} /></button>

                    }
                  </form>
                </div>
              </div>
              <hr className='mt-4' />

              <>

                {
                  purchaseDetails.map((purchaseDetails) => {
                    return <div>

                      <div className="row g-0 mt-3" key={purchaseDetails.id}>
                        <div className="col-2 p-1 col-md-2">
                          {
                            purchaseDetails.image ?
                              <img src={purchaseDetails.image} className="rounded" width={80} />
                              :
                              <img src={user} className="rounded" width={80} />
                          }

                        </div>
                        <div className="col-7">
                          <div className="card-body pt-1 pb-2">
                            <h6 className="card-title"><b>Titulo: {purchaseDetails.title} </b> <a href={purchaseDetails.link} target="_blank" rel="noreferrer">Ver link</a></h6>
                            <span className="card-text">Quantidade: {purchaseDetails.quantity}</span> <br />
                            {
                              purchaseDetails.size
                                ?
                                <span className="card-text">Tamanho: {purchaseDetails.size}</span>
                                : ""
                            }
                            <br />
                            {
                              purchaseDetails.color
                                ?
                                <span className="card-text">Cor: {purchaseDetails.color}</span>
                                : ""

                            }
                            <br />
                            <span className="card-text">Preço: {formatPrice(parseInt(purchaseDetails.price))}</span>
                          </div>
                        </div>
                      </div>

                    </div>
                  })
                }
              </>


            </div>
          </div>
          <div className="card mb-4 border-0">
            {
              sellerDetails
                ?
                <div className="card-body">
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      {
                        sellerDetails.foto ?
                          <img src={sellerDetails.foto} className="rounded" width={80} />
                          :
                          <img src={user} className="rounded" width={80} />
                      }

                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h4>{sellerDetails.name}</h4>
                      Telefone: {sellerDetails.telefone}  <br />
                      Email: {sellerDetails.email} <br />
                      Localização: {sellerDetails.address}
                    </div>
                  </div>
                </div>
                :
                <div className="card-body">
                  <div className="d-flex">
                    <div className="flex-shrink-0">
                      <img src={user} alt="..." width={80} className="rounded" />
                    </div>
                    <div className="flex-grow-1 ms-3">
                      <h4>Josue Francisco</h4>
                      Telefone:999 999 999  <br />
                      Email: Josue@Francisco.com <br />
                      Localização: Luanda / Cazenga
                    </div>
                  </div>
                </div>
            }

          </div>
          <Link to="/costumer/dashboard">Voltar</Link>
        </div>
      </div>
    </div>
  </>);
}
export default Inf_produt;